import React, {useState, useEffect} from 'react';
import { PaystackButton } from "react-paystack"
import Logo from './Logo';
import { useDispatch, useSelector } from 'react-redux';
import { logoutAsync, refreshTokenAsync } from '../redux/actions';
import {useNavigate} from 'react-router-dom'
import { toast } from 'react-toastify';
import { makePreferredPaymentAsync } from '../redux/actions';
import StorageService from '../utils/storageService';

export default function UpdateCardDetails() {
    const dispatch = useDispatch()
    const navigate = useNavigate() 
    const storageService = new StorageService()
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(false)
    const [errorText, setErrorText] = useState(false)
    const [successText, setSuccessText] = useState('')
    const [preferredPayment, setPreferredPayment] = useState('')
    const {accessToken, login_data, companyDetails, userDetails, email} = useSelector(
        (state) => state.auth
      );
    const verifyPayment = (reference) => {
        let myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append('Authorization',`Bearer ${accessToken}`)
        setLoading(true)
        var requestOptions = {
        method: 'PUT',
        headers: myHeaders,
        redirect: 'follow'
        };

        fetch(`${process.env.REACT_APP_BACKEND_BASEURL}customer/payment-details?paymentReference=${reference}`, requestOptions)
        .then(response => {
            if(response.status === 401){
                dispatch(refreshTokenAsync())
            }   
            return response.json()
        })
        .then(result => {
            setLoading(false)
            // console.log(result)
            if(result.succeded === false){
                setError(true)
                setErrorText(result.message)
            }else{
                setSuccessText('Bank Card Updated')

                if(preferredPayment === 0){
                    let auth_data = storageService.getAuthData()
                    if(typeof auth_data.accessToken !== "undefined"){
                        dispatch(makePreferredPaymentAsync({
                            option: 2,
                            accessToken: auth_data.accessToken},
                            false,
                            ()=>{
                              navigate('/payment-options') 
                            }))
                    }
                
                }
            }
        })
        .catch(error => {
            setError(true)
            setErrorText('Error Updating card details')
        });
    }

   
    useEffect(()=>{
        if(errorText !== ''){
            toast.error(errorText, {
              position: "top-right",
              autoClose: 5000,
              onClose: () => {
                setErrorText('')
              }
            })
        }
    },[errorText])


    useEffect(()=>{
        if(successText !== ''){
            toast.success(successText, {
              position: "top-right",
              autoClose: 5000,
              onClose: () => {
                setSuccessText('')
              }
            })
        }
    },[successText])

    useEffect(()=>{
        if(typeof userDetails.paymentMethod !== "undefined"){
          setPreferredPayment(userDetails.paymentMethod)
        }
      },[userDetails])


    return (
        <div className='bnpl_modal'>
            <div className='profile_name'
            onClick={()=>{
                navigate('/history')
            }}
            >
                <div className='icon_ back'></div>
                {`Back to home`}</div>
            <Logo />
            {(typeof email !== 'undefined' && companyDetails !== 'undefined') && 
            <>
                {(typeof companyDetails.serviceFee !== 'undefined') && !loading &&
                <>
                    {accessToken !== '' &&
                    <div className='logout bg-gray-800'
                        onClick={()=>{
                        dispatch(logoutAsync())
                        }}
                    >Logout</div>
                    }
                    

                    <div className='title'>Link bank card</div>
                    <div className='modal_body'>
                        <strong>50.00 Naira</strong> will be debitted from your card to verify the card details.
                        <br /><br />
                    </div>
                    < div className='pay'>
                        <PaystackButton
                            email={email}
                            amount={5000}
                            metadata={
                                {...userDetails, ...companyDetails, type: 'Statutory'}
                            }
                            publicKey={process.env.REACT_APP_PAYSTACK_KEY}
                            text={`Update card details`}
                            onSuccess={(response) => {
                                verifyPayment(response.reference)
                                
                            }}
                            onClose={() => {
                                
                            }}
                        />
                    </div>
                </>
                }
                {loading &&
                    <div className='modal_body'>
                    ... Updating Payment Card
                    <br /><br />
                    </div>
                }
            </>
            }
        </div>
    );
}
