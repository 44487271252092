import React,{useEffect, useState} from 'react'
import { useMono } from 'react-mono-js';
import MonoConnect from '@mono.co/connect.js';
import { useDispatch, useSelector } from 'react-redux';
import { addMonoAsync} from '../redux/actions';
import {useNavigate} from 'react-router-dom'
import {logoutAsync, refreshTokenAsync} from '../redux/actions'
import Logo3 from './Logo3'
import Footnotes from './Footnotes';


function UpdateMono() {
  // current_decision
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [processing, setProcessing] = useState(false)
    const [loading, setLoading] = useState(false)
    const handleMono = useMono({ public_key: process.env.REACT_APP_MONO_KEY })
    const {accessToken, userDetails,companyDetails, current_decision} = useSelector(
      (state) => state.auth
    )
    const [decisionId, setDecisionId] = useState(0)
    const addMono = (monoCode) =>{
        var requestOptions = {
            method: 'GET',
            redirect: 'follow',
            headers: {
              'Authorization': `Bearer ${accessToken}`,
            }
          };
          setLoading(true)
          
          fetch(`${process.env.REACT_APP_BACKEND_BASEURL}customer/update-bank-link?monoCode=${monoCode}&decisionId=${decisionId}`, requestOptions)
            .then(response => {
              if(response.status === 401){
                dispatch(refreshTokenAsync())
              }   
              return response.json()
            })
            .then(result => {
              setTimeout(() => {
                setLoading(false)
                  dispatch(addMonoAsync(result, false,
                   ()=>{
                    
                   }
                ))
              }, 7000)
            })
            .catch(error => 
                {
                    setLoading(false)
                    dispatch(addMonoAsync(error, true, ()=>{

                    })
                );
              })
      }
    
    const proceedToMono = () => {
        setProcessing(false)
        handleMono({
            onClose: () => {

            },
            onSuccess: (response) => {
              if(typeof response.code !== 'undefined'){
                addMono(response.code)
              }
            }
        })
    }
  
    const monoConnect = React.useMemo(() => {
      const customer = {
        name: userDetails.firstName+' '+userDetails.lastName,
        email: userDetails.email
      }
      const monoInstance = new MonoConnect({
        onClose: () => console.log('Widget closed'),
        onLoad: () => console.log('Widget loaded successfully'),
        onSuccess: ({ code }) => {
          if(typeof code !== 'undefined'){
            addMono(code)
          }
          console.log(`Linked successfully: ${code}`)
        },
        key: process.env.REACT_APP_MONO_KEY,
        data: {customer}
      })
      monoInstance.setup()    
      return monoInstance;
    }, [])
  
  useEffect(()=>{
    if(typeof current_decision.id !== "undefined"){
      setDecisionId(current_decision.id)
    }
  }, [current_decision])
  return (
    <>
      {decisionId !== 0 &&
        <div className='bnpl_modal'>
            {accessToken !== '' && 
              <div className='logout bg-gray-800'
                onClick={()=>{
                  dispatch(logoutAsync())
                }}
              >Logout</div>
            }

            <div className='profile_name'
            onClick={()=>{
            navigate('/history')
            }}
            >
            <div className='icon_ back'></div>
            {`Back to home`}</div>
            <Logo3 />
            {!loading &&
            <div className='white_box small_box modal_content'>
                <div className='title'>Link Bank Statement</div>
                <div className='modal_body'>Your financial records are necessary to evaluate your eligibility for BNPL.</div>
                <div className='btn btn-primary'>
                  <div className='text' 
                  onClick={() => {
                    // proceedToMono() // comment this out to test without mono
                    monoConnect()
                  }}
                  >Add Bank Statement</div>        
                </div>
            </div>
            }
            {loading &&
                    <div className='modal_body'>
                    ... Linking Bank
                    <br /><br />
                    </div>
                }
            <Footnotes />
        </div>
      }
    </>
  )
}

export default UpdateMono