import axios from 'axios'
import React, { useState, useEffect } from 'react'
import CaptureSelfie from './CaptureSelfie'
import CaptureId from './CaptureId'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {getPendingDecisionsAsync, logoutAsync, setCurrentDecisionAsync} from '../../redux/actions'
import { toast } from 'react-toastify';
// import { _getTokenFromStorage } from "../../utils";
import { addKycAsync, finishKyc } from '../../redux/actions';
import Logo from '../Logo';
import Logo3 from '../Logo3';
import Footnotes from '../Footnotes';


function Kyc({current_decision}) {
  const {accessToken, companyDetails, decisionId, pending_decision} = useSelector(
    (state) => state.auth
  );
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [takeSelfie, setTakeSelfie] = useState(false)

  const [loading, setLoading] = useState(false);
  const [idFile, setIdFile] = useState(null)
  const [idSrc, setIdSrc] = useState(null)
  const [photoFile, setPhotoFile] = useState(null)
  const [photoSrc, setPhotoSrc] = useState(null)
  const [idType, setIdType] = useState("")
  const [vnin, setVnin] = useState("")
  const [step, setStep] = useState(1)
  const [uploadType, setUploadType] = useState(null)
  const [docImage, setDocImage] = useState(null)
  const [selfieImage, setSelfieImage] = useState(null)
  const [shortCode, setShortCode] = useState("")

  const refreshPending = ()=>{
    dispatch(
    getPendingDecisionsAsync({accessToken, companyShortCode: companyDetails.shortCode},
      ()=>{
        if(decisionId !== null && pending_decision !== null){
          if(typeof pending_decision[0] !== 'undefined'){
            let m = pending_decision.filter((pd)=>{
              if(pd.id === decisionId){
                return true
              }else{
                return false
              }
            })
            dispatch(setCurrentDecisionAsync(
              {current_decision: m[0]}
            ))
          }
        }
      }
    )
    )
  }

  function dataURLtoFile(dataurl, filename) {
    var arr = dataurl.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
  }
  const doKyc = () => {
    setLoading(true)
    var selfie_ = dataURLtoFile(photoSrc, 'cbiphoto.jpg');

    if (uploadType === "camera") {
      var id_ = dataURLtoFile(idSrc, 'cbimage.jpg');
    } else {
      var id_ = idFile
    }

    // const token = _getTokenFromStorage("accessToken");

    const FormData = require('form-data');
    let data = new FormData();
    data.append('idSnapShot', id_);
    data.append('selfie', selfie_);
    let config = {
      method: 'PUT',
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_BACKEND_BASEURL}customer/kyc?decisionId=${decisionId}&idType=${idType}`,
      headers: {
        'Authorization': `Bearer ${accessToken}`,
      },
      data: data
    };

    axios.request(config)
      .then((response) => {
        setLoading(false)
        refreshPending()
        console.log('success', response)
        
        if(companyDetails.isKYCRequired){
          dispatch(addKycAsync(response, false, ()=>{}))
          
          // toast.success(response.data.message, {
          //   position: "top-right",
          //   autoClose: 5000,
          //   onClose: () => {
          //   }
          // })


        }else{
          dispatch(finishKyc())
        }
        

      })
      .catch((err) => {
        if (err.response) {
          refreshPending()
            let statusCode = err.response.status
            console.log(statusCode)
            setLoading(false)
            if(statusCode === 500){ 
              toast.error(err.message, {
                position: "top-right",
                autoClose: 5000,
                onClose: () => {
                }
              })  
            }
            // else{
            if(companyDetails.isKYCRequired){
                console.log('failed', err)
                // toast.error(err.message, {
                //   position: "top-right",
                //   autoClose: 5000,
                //   onClose: () => {
                //   }
                // })
                dispatch(addKycAsync(err, true, ()=>{}))
              }else{
                dispatch(finishKyc())
              }
            // }
        }
      });


    // 
  }
  useEffect(()=>{
    if(typeof companyDetails.shortCode !== 'undefined'){
        setShortCode(companyDetails.shortCode.toString().trim())
    }
  },[companyDetails])
  const next = () => {
    setStep(2)
  }
  return (
    <>
    <div className='bnpl_modal'>
        {accessToken !== '' &&
          <div className='logout bg-gray-800'
            onClick={()=>{
              dispatch(logoutAsync())
            }}
          >Logout</div>
        }
        <div className='profile_name'
            onClick={()=>{
                navigate('/history')
            }}
            >
                <div className='icon_ back'></div>
                {`Back to home`}</div>
        <Logo3 />

      

      <div className='modal_content'>
        
        {step === 1 &&
          < CaptureSelfie
            next={next}
            photoFile={photoFile}
            setPhotoFile={setPhotoFile}
            photoSrc={photoSrc}
            setPhotoSrc={setPhotoSrc}
          />
        }
        {step === 2 &&
          <CaptureId
            next={doKyc}
            idFile={idFile}
            setIdFile={setIdFile}
            idSrc={idSrc}
            setIdSrc={setIdSrc}
            idType={idType}
            setIdType={setIdType}
            vnin={vnin}
            setVnin={setVnin}
            uploadType={uploadType}
            setUploadType={setUploadType}
            loading={loading}

          />
        }
      </div>
      <Footnotes />
      </div>
    </>


  )
}

export default Kyc