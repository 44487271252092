import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { logoutAsync
} from '../redux/actions';
import { useNavigate } from 'react-router-dom';
import Logo3 from './Logo3';
import check from '../assets/check.svg'
import back from '../assets/back.svg'
import Footnotes from './Footnotes';

export default function TermsOfUse() {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    
    const {accessToken, companyDetails,
    } = useSelector(
        (state) => state.auth
    )
    return (
        <div className='p-[20px]'>
            {/* <Logo3 
                title={typeof companyDetails !== "undefined" ? companyDetails.name : ""}
            />
            <div className='flex flex-row gap-[20px] py-[5px] cursor-pointer mt-[30px]'>
                    <div className='text-xs 
                    bg-gray-200 py-[4px] px-[10px]
                    flex flex-row rounded-[13px]'
                        onClick={()=>navigate('/')}
                    >
                        <img src={back} className='w-[15px] h-[15px]' alt="back icon"/>
                        <div className=''>Back</div>
                    </div>
                    <div className='text-xs pt-[3px]
                    font-bold
                    '>{`Terms of use`}</div>
            </div>
            {accessToken !== '' && typeof userDetails !== 'undefined' &&
                <div className='logout bg-gray-800'
                    onClick={()=>{
                    dispatch(logoutAsync())
                    }}
                >Logout</div>
            } */}
            <div
                className='m-auto text-center mt-[10px] mb-[20px] font-bold'
            >How we use your data</div>

            

            <div className='datauseterms text-[12px] 
            max-h-[300px] overflow-auto
            bg-green-100 p-[15px] rounded-[10px]'>
                <div className="paragraph pb-[10px]">To provide our <b>Buy Now, Pay Later (BNPL)</b> service, we require certain personal information to verify your identity and assess your eligibility. This includes your phone number, email address, BVN, Bank statements via mobile/internet banking app, and a valid ID (Driver's license, NIN, or passport).
                </div>
                <div className="paragraph pb-[10px]">
                We prioritize data security and employ robust verification processes to prevent fraud and ensure your information remains confidential. The requested details are essential for authenticating your identity, assessing your eligibility for the <b>BNPL</b> service, and ensuring a smooth transaction. Your personal information is strictly protected and will not be shared with third parties. We will only retain your data for the duration of your <b>BNPL</b> agreement.
                </div>
                <div className="paragraph pb-[10px]">
                IziFin Technologies Limited is an NDPR-certified company that is dedicated to protecting your data. We adhere to rigorous security standards and implement best practices to prevent cyber threats and ensure data integrity.
                </div>
                <div className="paragraph pb-[10px]">
                Please be assured that your information is transmitted securely, and the risk of data theft or cybercrime is minimal.
                </div>
            </div>
        </div >
    );
}
