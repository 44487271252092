import React from 'react'
import loading from '../assets/loading.gif'
export default function Preloader() {
  return (
    <div className='bg-[#f2f2f2] h-full w-full flex justify-center content-center items-center gap-4'>
        <img src={loading} className="w-[30px] h-[30px]"/>
        <div className='saira-400 text-blue'>...Login Page Loading</div>
    </div>
  )
}
