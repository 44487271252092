import React,{useState} from 'react'
import axios from 'axios'
import { toast } from 'react-toastify'
import { useMono } from 'react-mono-js'
import { useDispatch, useSelector } from 'react-redux'
import { addMonoAsync, getPendingDecisionsAsync, setCurrentDecisionAsync } from '../../redux/actions';
import {useNavigate} from 'react-router-dom'
import {logoutAsync, refreshTokenAsync} from '../../redux/actions'
import Logo3 from '../Logo3';
import UploadBankStatement from '../UploadBankStatement'
import Footnotes from '../Footnotes'
import TermsOfUse from '../TermsOfUse'
import cancel from '../../assets/cancel-35.svg'

function Mono({current_decision}) {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [statementType, setStatementType] = useState('mono')
    const [showTerms, setShowTerms] = useState(true)
    const [processing, setProcessing] = useState(false)
    const [loading, setLoading] = useState(false)
    const handleMono = useMono({ public_key: process.env.REACT_APP_MONO_KEY })
  

    const [idSrc, setIdSrc] = useState(null)
    const [idFile, setIdFile] = useState(null)
    const [allowedBank, setAllowedBank] = useState('')
    const [filePassword, setFilePassword] = useState('')
    const [submittingDocument, setSubmittingDocument] = useState(false)
    const [fileError, setFileError] = useState("")

    const {accessToken, decisionId, pending_decision, companyDetails} = useSelector(
      (state) => state.auth
    );
     
    const refreshPending = ()=>{
      dispatch(
      getPendingDecisionsAsync({accessToken, companyShortCode: companyDetails.shortCode},
      ()=>{
        if(decisionId !== null && pending_decision !== null){
          if(typeof pending_decision[0] !== 'undefined'){
            let m = pending_decision.filter((pd)=>{
              if(pd.id === decisionId){
                return true
              }else{
                return false
              }
            })
            dispatch(setCurrentDecisionAsync(
              {current_decision: m[0]}
            ))
          }
        }
      })
      )
    }  
  
    const addMono = (monoCode) =>{
      var requestOptions = {
      method: 'GET',
      redirect: 'follow',
      headers: {
        'Authorization': `Bearer ${accessToken}`,
      }
      };
      setLoading(true)
      fetch(`${process.env.REACT_APP_BACKEND_BASEURL}customer/update-bank-link?monoCode=${monoCode}&decisionId=${decisionId}`, requestOptions)
      .then(response => {
        let jsonResponse = response.json()
        if(response.status === 401){
          dispatch(logoutAsync())
        }
        return jsonResponse
      })
      .then(result => {
        if(typeof result)
        setTimeout(() => {
          setLoading(false)
          refreshPending()
          // dispatch(addMonoAsync(result, false,
          //   refreshPending()
          // ))
        }, 4000)
      })
      .catch(error => 
          {
              if (error.status) {
                // Handle the status code
                console.error(`Error status code: ${error.status}`);
                console.log(error.details)
                if(error.status === 401){
                  console.log(error.message)
                  refreshPending()
                }
              } else {
                  console.error('Fetch error:', error);
              }
              setLoading(false)
              dispatch(addMonoAsync(error, true, ()=>{})
          );
        }
      )
    }

    const submitBankStatement = () =>{
      setSubmittingDocument(true)
      const FormData = require('form-data');
      let data = new FormData();
      data.append('bankStatement', idFile);
      let config = {
      method: 'POST',
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_BACKEND_BASEURL}customer/bank-statement?filePassword=${filePassword}&bankCode=${allowedBank}&decisionId=${decisionId}`,
      headers: {
      'Authorization': `Bearer ${accessToken}`,
      },
      data: data
      };

      axios.request(config)
      .then((response) => {
        setSubmittingDocument(false)
        // dispatch(addMonoAsync(result, false,
        //   refreshPending()
        // ))
        refreshPending()
        toast.success(response.data.message, {
          position: "top-right",
          autoClose: 5000,
          onClose: () => {
          }
        })
    })
    .catch((err) => {
      let msg = ""
      if (typeof err !== "undefined") {
        setSubmittingDocument(false)
        refreshPending()
          
          if(typeof err.response !== "undefined"){
            msg = err.response.data.message  
          }else if(typeof err.message !== "undefined"){
            msg = "Error reading bank statement, Ensure you use the statement from the bank you selected."
          }else{
            msg = "Error reading bank statement, Ensure you use the statement from the bank you selected."
          } 
        }else{
          msg = "Error reading bank statement, Ensure you use the statement from bank you selected."
        }
        setFileError(msg)
        toast.error(msg, {
          position: "top-right",
          autoClose: 5000,
          onClose: () => {
          }
        })  
      });



    }
    
    const resetUpload = () =>{
      setFileError("")
      setIdSrc(null)
      setIdFile(null)
      setFilePassword(false)
      setAllowedBank(null)
      setSubmittingDocument(false)

    }
    const proceedToMono = () => {
        setProcessing(false)
        handleMono({
            onClose: () => {

            },
            onSuccess: (response) => {
              if(typeof response.code !== 'undefined'){
                addMono(response.code)
              }
            }
        })
    }
  return (
    <>
    <div className='bnpl_modal relative !min-h-[600px]'>
        {accessToken !== '' && 
          <div className='logout bg-gray-800'
            onClick={()=>{
              dispatch(refreshTokenAsync())
            }}
          >Logout</div>
        }
        {showTerms &&

        <div className='absolute bg-white w-[80%] ml-[5%] 
        mt-[30px] h-[400px] rounded-[10px] shadow-md'>

          <div className='absolute w-[20px] right-[10px] mt-[10px]
          h-[20px] rounded-full cursor-pointer'
          onClick={()=>{
            setShowTerms(false)
          }}
          >
            <img src={cancel} alt="cancel-icon"/>
          </div>
          <TermsOfUse />
        </div>
        }
        <div className='flex !gap-4 h-[25px] mt-[20px] mb-[40px]'>
            <div className='profile_name'
            onClick={()=>{
            navigate('/history')
            }}
            >
              <div className='icon_ back'></div>
            </div>
            <div className='leading-[40px] font-bold'>Bank Statement</div>
        </div>
          
        {!submittingDocument && fileError === "" &&
        <div className={`toggle_options file_option_tab ${loading ? 'fade' : ''}`}>
            
            <div className={`toggle_btn ${statementType === "mono" ? "active" : ""}`}
              onClick={() => {
                if(!loading){
                  setStatementType('mono')
                }
              }}
            >Login to Bank</div>

            <div className={`toggle_btn ${statementType === "upload" ? "active" : ""}`}
              onClick={() => {
                setStatementType('upload')
              }}>Upload Bank Statement</div>
        </div>
        }


        <Logo3 />
        {!loading && statementType === 'mono' &&
        <div className='px-[20px] pt-[5px] pb-[20px] !bg-white rounded-[5px]'>
            
            <div className='modal_body'>Your financial records are necessary to evaluate your eligibility for BNPL.</div>
            <div className='btn btn-primary'>
              <div className='text' 
              onClick={() => {
                proceedToMono() // comment this out to test without mono
              }}
              >Add Bank Statement</div>        
            </div>
        </div>
        }

        {!loading && statementType === 'upload' &&
        <UploadBankStatement 
          idSrc={idSrc} 
          setIdSrc={setIdSrc}
          idFile={idFile}
          setIdFile={setIdFile}
          allowedBank={allowedBank} 
          setAllowedBank={setAllowedBank}
          filePassword={filePassword} 
          setFilePassword={setFilePassword}
          submitBankStatement={submitBankStatement}
          submittingDocument={submittingDocument}
          setSubmittingDocument={setSubmittingDocument}     
          fileError={fileError} 
          resetUpload={resetUpload}  
          />
        }



        {loading &&
              <div className='modal_body'>
              ... Linking Bank
              <br /><br />
              </div>
          }
        <div className='absolute bottom-[20px] w-[100%]'>
          <Footnotes />
        </div>
    </div>
    </>
  )
}

export default Mono