import { generateActions } from "../../utils";

export const USER_LOGIN = "USER_LOGIN";
export const GET_TOKEN = "GET_TOKEN";
export const ADD_MONO = "ADD_MONO";
export const ADD_KYC = "ADD_KYC";
export const REQUEST_LOAN = "REQUEST_LOAN";
export const ACCEPT_LOAN = "ACCEPT_LOAN";
export const USER_DETAILS = "USER_DETAILS";
export const GET_AUTH_COOKIES = "GET_AUTH_COOKIES"
export const COMPANY_DETAILS = "COMPANY_DETAILS"
export const CLEAR_COMPANY_DETAILS = "CLEAR_COMPANY_DETAILS"
export const CLEAR_LOAN_REQUEST = "CLEAR_COMPANY_DETAILS"
export const CREATE_USER_WALLET = "CREATE_USER_WALLET"
export const CLEAR_ERROR = "CLEAR_ERROR"
export const CLEAR_SUCCESS = "CLEAR_SUCCESS"
export const UPDATE_PAYMENT_DETAILS = 'UPDATE_PAYMENT_DETAILS'
export const CHANGE_LOANSTEP = 'CHANGE_LOANSTEP'
export const SET_LOANSTEP = 'SET_LOANSTEP'
export const GET_USER_WALLET = 'GET_USER_WALLET'
export const SET_CURRENT_DECISION = 'SET_CURRENT_DECISION'
export const GET_USER_WALLET_CONSENT_STATUS = 'GET_USER_WALLET_CONSENT_STATUS'
export const SET_LOAN_RESPONSE = 'SET_LOAN_RESPONSE'
export const SET_PREFERRED_PAYMENT = generateActions('SET_PREFERRED_PAYMENT')
export const GET_EVENTS = generateActions('GET_EVENTS')
export const CREATE_EVENT = generateActions('CREATE_EVENT')
export const CREATE_SESSION = generateActions('CREATE_SESSION')
export const SET_ERROR = generateActions('SET_ERROR')
export const GET_CONSENT_STATUS = generateActions('GET_CONSENT_STATUS')
export const CREATE_DECISION = generateActions('CREATE_DECISION')
export const GET_PENDING_DECISIONS = generateActions('GET_PENDING_DECISIONS')
export const GET_AVAILABLE_BANKS = generateActions('GET_AVAILABLE_BANKS')
export const REFRESH_TOKEN = generateActions('REFRESH_TOKEN')



