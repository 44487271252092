import React,{useEffect, useState} from 'react'
import {Routes, Route} from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import {getSavedCookiesAsync} from '../redux/actions/auth.action'

import Login from '../components/Login'
import ApplyLoan from '../pages/ApplyLoan'
import PageNotFound from '../pages/PageNotFound'


// import PaymentBreakdown from '../components/PaymentBreakdown';
import LoanHistory from '../components/LoanHistory';
import Landing from '../components/Landing'
import UpdateCardDetails from '../components/UpdateCardDetails'
import UpdateMono from '../components/UpdateMono'
// import PaymentPage from '../components/PaymentPage';
import PaymentOptions from '../pages/payment/PaymentOptions'
import CreateWallet from '../pages/wallet/CreateWallet'
import WalletBalance from '../pages/wallet/WalletBalance'
import LoanSteps from '../pages/instructions/LoanSteps'
import DecisionSteps from '../pages/instructions/DecisionSteps'

import UploadBankStatement from '../components/UploadBankStatement'
import TermsOfUse from '../components/TermsOfUse'

function AllRoutes() {
  const dispatch = useDispatch()

  const {accessToken, companyDetails} = useSelector(
    (state) => state.auth
  );
 
  const setCSSProperty = (name,value) => {
    document.documentElement.style.setProperty(`--${name}`, value);
  }
  useEffect(()=>{
    dispatch(getSavedCookiesAsync())
  },[])

  useEffect(()=>{
    if(JSON.stringify(companyDetails) !== '{}' && typeof companyDetails !== 'undefined'){
      if(companyDetails.primaryColourHexCode !== '' && companyDetails.primaryColourHexCode !== null){
        setCSSProperty('primaryColourHexCode',companyDetails.primaryColourHexCode)
      }
      if(companyDetails.secondaryColourHexCode !== '' && companyDetails.secondaryColourHexCode !== null){
        setCSSProperty('secondaryColourHexCode',companyDetails.secondaryColourHexCode)
      }
      if(companyDetails.logoUrl !== '' && companyDetails.logoUrl !== null){
        setCSSProperty('logoUrl',companyDetails.logoUrl)
      }

      if(companyDetails.primaryColourHexCode === '' || companyDetails.primaryColourHexCode === null){
        setCSSProperty('primaryColourHexCode', '#000000')
      }
      if(companyDetails.secondaryColourHexCode === '' || companyDetails.secondaryColourHexCode === null){
        setCSSProperty('secondaryColourHexCode', '#000000')
      }
    }
    
    
  },[companyDetails])

  return (
    <>
      <Routes>
        <Route exact path="/login" 
          element={typeof accessToken !== 'undefined' && accessToken.trim() !== '' && accessToken.trim() !== null ? 
          <LoanHistory /> : <Login /> } />
  
        <Route exact path="/" 
          element={typeof accessToken !== 'undefined' && accessToken !== '' && accessToken !== null ? 
            <LoanHistory /> : <Login /> } />
        
        <Route exact path="/decisionstep" 
          element={<DecisionSteps />} />

        <Route exact path="/termsofuse" 
          element={<TermsOfUse />} />
        
        <Route exact path="/loansteps" 
          element={<LoanSteps />} />

          {/* element={typeof accessToken !== 'undefined' && accessToken !== '' && accessToken !== null ? 
          <LoanSteps /> : <Login/> } /> */}

        <Route exact path="/request" 
          element={typeof accessToken !== 'undefined' && accessToken !== '' && accessToken !== null ? 
            <ApplyLoan />: < Login /> } />

        <Route exact path="/payment-options" 
          element={typeof accessToken !== 'undefined' && accessToken !== '' && accessToken !== null ? 
            <PaymentOptions /> : < Login /> } />
        
        <Route exact path="/create-wallet" 
          element={typeof accessToken !== 'undefined' && accessToken !== '' && accessToken !== null ? 
            <CreateWallet /> : < Login /> } />
        <Route exact path="/wallet" 
          element={typeof accessToken !== 'undefined' && accessToken !== '' && accessToken !== null ? 
            <WalletBalance /> : < Login /> } />
        
        <Route exact path="/history" 
          element={typeof accessToken !== 'undefined' && accessToken !== '' && accessToken !== null ? 
            <LoanHistory /> : < Login /> } />
        
        <Route exact path="/card" 
          element={typeof accessToken !== 'undefined' && accessToken !== '' && accessToken !== null ? 
            <UpdateCardDetails /> : < Login /> } />

          <Route exact path="/mono" 
            element={typeof accessToken !== 'undefined' && accessToken !== '' && accessToken !== null ? 
             <UpdateMono /> : < Login /> } />
          
          <Route path="*" element={<PageNotFound />} />
        </Routes>
      </>
  )
}

export default AllRoutes