import * as types from "../types";
import StorageService from "../../utils/storageService";
import { nextStepLoanRequest } from "../../utils";
const storageService = new StorageService();

const initialState = {
	loading: false,
	accessToken:'',
	email:'',
	refreshToken:'',
	consentURL:'',
	userExists: false ,
	tokenSent: false,
	monoAdded: false,
	kycAdded: false,
	decisionId:null,
	loanId:'',
	loanRequested: false,
	repaymentSchedule:0,
	statutoryPayment:false,
	repaymentSchedules:[],
	loanAccepted: false,
	preferredPayment:"",
	walletDetails:"",
	companyDetails: {
		secondaryColourHexCode:'',
		primaryColourHexCode:'',
		logoUrl:'',
		name:''
	},
	loanSteps : ["INITIAL_PAYMENT","LINK_BANK", "KYC", "REQUEST_LOAN", "ACCEPT_LOAN", "RESULT"],
	bnplStepsDone:[],
	currentLoanStep: 0,
	companyShortCode:'',
	loanResult:'',
	loanResponse:{},
	login_data:{},
	userDetails:{},
	consentStatus:{},
	errorMessage:'',
	successMessage:'',
	infoMessage: '',
	events:{},
	sessionCreated:false,
	wallet_status:false,
	current_decision:null,
	pending_decision:null,
	pendingIndex:null,
	creating_decision:false,
	decision:null,
	available_banks:null,
};

const AuthReducer = (
	state = initialState,
	{ type, payload, isError }
) => {
	switch (type) {	
		case types.REFRESH_TOKEN.REQUEST:{
			return{
				...state,
				loading: true
			}
		}
		case types.GET_PENDING_DECISIONS.REQUEST:{
			return{
				...state,
				loading: true
			}
		}
		case types.GET_AVAILABLE_BANKS.REQUEST:{
			return{
				...state,
				loading: true
			}
		}
		case types.CREATE_DECISION.REQUEST:{
			return{
				...state,
				loading: true,
				creating_decision: true
			}
		}
		case types.GET_EVENTS.REQUEST:
		case types.CREATE_SESSION.REQUEST:
		case types.SET_PREFERRED_PAYMENT.REQUEST:
		case types.CREATE_EVENT.REQUEST:
		{
			return {
				...state,
				loading: true
			}
		}
		case types.GET_USER_WALLET_CONSENT_STATUS:{
			return {
				...state,
				loading:false,
				wallet_status: payload,
			}
		}
		case types.USER_LOGIN:
		{
			let token = ''
			let refreshToken=''
			let errorMessage = ''
			let email = ''
			let login_data = ''
			if(!isError && typeof payload.result !== 'undefined'){
				if(payload.result.succeded){
					if(typeof payload.result.data !== "undefined"){
						if(typeof payload.result.data.accessToken !== 'undefined'){
							  token = payload.result.data.accessToken
							  refreshToken = payload.result.data.refreshToken
							  email = payload.result.data.email
							  login_data = payload.result.data
						}
					}
				}
			}else if(isError){
				errorMessage = ` ${payload.message}: Logging in user`
			}else{
				errorMessage = `Error while logging user in`
			}
			
			return {
				...state,
				accessToken: token,
				refreshToken,
				login_data,
				email,
				errorMessage
			}
		}
		
		case types.SET_LOAN_RESPONSE:{
			// console.log(payload)
			return {
				...state,
				loanResponse:{
					status: payload.status,
					message: payload.message
				},
				loanResult: payload.message,
				loanAccepted: payload.status
			}
		}

		case types.SET_CURRENT_DECISION:{
			// console.log(payload)
			let pendingIndex = state.pendingIndex
			if(typeof payload.pendingIndex !== 'undefined'){
				pendingIndex = payload.pendingIndex
			}
			return {
				...state,
				loading:false,
				pendingIndex,
				decisionId:payload.current_decision.id,
				current_decision: payload.current_decision,
			}
		}
		case types.SET_PREFERRED_PAYMENT.SUCCESS:{
			return {
				...state,
				loading:false,
				successMessage: payload.message,
				preferredPayment: payload
			}
		}

		case types.REFRESH_TOKEN.SUCCESS:{
			if(typeof payload.data !== "undefined"){
				let refreshToken = payload.data.refreshToken
				let accessToken = payload.data.accessToken
				let email = payload.data.email
			
				storageService.saveAuthData({
					accessToken: accessToken,
					refreshToken: refreshToken,
					email: email
				})
				return {
					...state,
					loading:false,
					accessToken: accessToken,
					refreshToken: refreshToken,
					email: email
				}
			}else{
				return {
					...state,
					loading:false
				}
			}
			
		}
		case types.GET_PENDING_DECISIONS.SUCCESS:{
			return {
				...state,
				loading:false,
				successMessage: payload.message,
				pending_decision: payload.pending_decision,
			}
		}


		case types.GET_AVAILABLE_BANKS.SUCCESS:{
			return {
				...state,
				loading:false,
				available_banks: payload.banks,
			}
		}
		case types.CREATE_DECISION.SUCCESS:{
			return {
				...state,
				loading:false,
				creating_decision: false,
				successMessage: payload.message,
				pending_decision: payload.decision
			}
		}
		case types.GET_EVENTS.SUCCESS:{
			return {
				...state,
				loading:false,
				events: payload.events
			}
		}
		case types.SET_ERROR.SUCCESS:{
			let errorMessage = payload
			return {
				...state,
				errorMessage
			}
		}
		case types.CREATE_SESSION.SUCCESS:{
			return {
				...state,
				loading:false,
				sessionCreated: payload.sessionCreated
			}
		}
		case types.CREATE_EVENT.SUCCESS:{
			return {
				...state,
				loading:false,
			}
		}
		case types.GET_TOKEN:
		{
			let userExists = false 
			let tokenSent = false
			let errorMessage = ''
			if(payload.succeded && !isError){
				tokenSent = payload.succeded
				if(typeof payload.data !== 'undefined'){
					if(typeof payload.data.userExists !== 'undefined'){
						userExists = payload.data.userExists
					}
				}
			}else if(isError){
				errorMessage = ` ${payload.message}: getting token`
			}else{
				errorMessage = `Error getting token`
			}
			return {
				...state,
				userExists: userExists,
				tokenSent: tokenSent,
				errorMessage
			}
		}
		case types.CLEAR_LOAN_REQUEST:{
			return {
				...state,
				loanId: '',
				currentLoanStep: 0,
				loanRequested: false,
				loanResult: '',
				repaymentSchedules:[],
				bnplStepsDone:[]
			}
		}
		case types.SET_LOANSTEP:
		{

			return {
				...state,
				currentLoanStep: payload.currentLoanStep,
				bnplStepsDone: payload.bnplStepsDone
			}
		}
		case types.CHANGE_LOANSTEP:
		{
			let bnplStepsDone = state.bnplStepsDone
			let currentLoanStep = state.currentLoanStep
			let bankLinked = state.userDetails.bankLinked
			let kycCompleted = state.userDetails.kycCompleted
			
			if(bnplStepsDone.indexOf(payload.id) < 0 && payload.id !== 0){
				bnplStepsDone.push(payload.id)
				currentLoanStep = nextStepLoanRequest(state.userDetails, state.companyDetails, state.currentLoanStep, state.loanSteps)
			}
			
			return {
				...state,
				currentLoanStep,
				bnplStepsDone
			}
		}
		case types.CLEAR_ERROR:
		{
			return {
				...state,
				errorMessage: '' 
			}
		}
		case types.CLEAR_SUCCESS:
		{
			return {
				...state,
				successMessage: '' 
			}
		}
		case types.ADD_MONO:
		{
			let errorMessage = ''
			if(isError){
				errorMessage = ` ${payload.message}: linking bank`
			}
			return {
				...state,
				monoAdded: true,
				errorMessage,
			}
		}
		case types.ADD_KYC:
		{	
			let errorMessage = ''
			if(isError || !payload.succeded){
				console.log(payload)
				console.log("ERORE")
				if(typeof payload === "undefined"){
					errorMessage = `An error occured during KYC.`
				}else if(typeof payload.message === "undefined"){
					errorMessage = `An error occured during KYC.`
				}else{
					errorMessage = ` ${payload.message}`
				}
				
			}
			return {
				...state,
				kycAdded: true ,
				errorMessage
			}
		}
		case types.REQUEST_LOAN:
		{
			let loanRequested = false
			let repaymentSchedules = []
			let errorMessage = ''
			let loanId = ''
			if(!isError){
				if(payload.succeded){
					if(typeof payload.data !== 'undefined'){
						if(typeof payload.data.repaymentSchedules){
							repaymentSchedules = payload.data.repaymentSchedules
							loanId = payload.data.id
							loanRequested = true
						}
					}
				}else{
					errorMessage = ` ${payload.message}`
				}
			}else{
				errorMessage = ` ${payload.message}: requesting loan`
			}
			return {
				...state,
				repaymentSchedules,
				loanRequested,
				loanId,
				errorMessage
			}
		}
		case types.ACCEPT_LOAN:
		{
			let loanResult = ''
			let loanAccepted = false
			let errorMessage
			if(!isError){
				if(typeof payload.succeded !== 'undefined'){
					loanResult = payload.message
					loanAccepted = payload.succeded
				}else{
					errorMessage = ` ${payload.message}: accepting loan`
				}
			}else{
				errorMessage = ` ${payload.message}: accepting loan`
				loanResult = payload.message
			}
			
			return {
				...state,
				loanResult: loanResult,
				loanAccepted: loanAccepted,
				errorMessage
			}
		}
		case types.USER_DETAILS:
		{
			let userDetails = []
			let errorMessage = ''
			let companyDetailsStringified = JSON.stringify(state.companyDetails)
			let userDetailsStringified = ''
			if(!isError){
				if(payload.succeded){
					if(typeof payload.data !== 'undefined'){
						userDetails = payload.data
						userDetailsStringified = JSON.stringify(userDetails)
						storageService.saveAuthData({
							userId: userDetails.id,
							accessToken: state.accessToken,
							refreshToken: state.refreshToken,
							email: state.email,
							companyDetails: companyDetailsStringified,
							userDetails: userDetailsStringified
						})
					}
				}
			
				
			}
			// else{
			// 	errorMessage = ` ${payload.message}: user details`
			// }
			return {
				...state,
				userDetails,
				errorMessage
			}
		}
		case types.GET_USER_WALLET:
		{
			let errorMessage = ''
			let walletDetails = state.walletDetails
			let successMessage = ''
			if(payload.succeded){
				if(typeof payload.data !== "undefined"){
					walletDetails = payload.data
				}else{
					walletDetails = payload
				}
				successMessage = `${payload.message}`
			}else{
				errorMessage = `${payload.message}`
			}

			return {
				...state,
				walletDetails,
				errorMessage
			}
		}
		case types.GET_CONSENT_STATUS:
		{
			let errorMessage = ''
			let consentStatus = state.consentStatus
			let successMessage = ''
			if(payload.succeded){
				if(typeof payload.data !== "undefined"){
					consentStatus = payload.data
				}else{
					consentStatus = payload
				}
				successMessage = `${payload.message}`
			}else{
				errorMessage = `${payload.message}`
			}

			return {
				...state,
				consentStatus,
				errorMessage
			}
		}
		case types.CREATE_USER_WALLET:
		{
			let errorMessage = ''
			let consentURL = ''
			let successMessage = ''
			if(payload.succeded){
				successMessage = `${payload.message}`
				if(typeof payload.data !== "undefined"){
					consentURL = payload.data.consentURL
				}else{
					errorMessage = `Error getting the Consent URL, contact Admin`
				}
				
			}else{
				errorMessage = `${payload.message}`
			}

			return {
				...state,
				consentURL,
				successMessage,
				errorMessage
			}
		}
		case types.COMPANY_DETAILS:
		{
			let companyDetails = {}
			let errorMessage = ''
			if(!isError){
				if(payload.succeded){
					if(typeof payload.data !== 'undefined'){
						companyDetails = payload.data
					}
				}
			}else{
				errorMessage = ` ${payload.message}: company details`
			}
			return {
				...state,
				errorMessage,
				companyDetails
			}
		}
		case types.CLEAR_COMPANY_DETAILS:
		{
			let companyDetails = {}
			companyDetails = {
				secondaryColourHexCode:'',
				primaryColourHexCode:'',
				logoUrl:"",
				name:''
			}
			return {
				...state,
				companyDetails
			}
		}
		case types.UPDATE_PAYMENT_DETAILS:
		{
			let statutoryPayment = false
			let errorMessage = ''
			if(!isError){
					if(typeof payload.reference !== 'undefined'){
						statutoryPayment = true
					}else{
						errorMessage = 'Error Making Payment'
					}
				
			}else{
				errorMessage = 'Error Making Payment'
			}
			return {
				...state,
				statutoryPayment,
				errorMessage
			}
		}
		case types.GET_AUTH_COOKIES:
		{
			let currentLoanStep = state.currentLoanStep
			if(payload.accessToken === ''){
				currentLoanStep = -1
			}
			return {
				...state,
				email: payload.email,
				accessToken: payload.accessToken,
				refreshToken: payload.refreshToken,
				companyDetails: payload.companyDetails,
				userDetails: payload.userDetails,
				tokenSent: false,
				currentLoanStep
			}
		}
		case types.GET_EVENTS.FAILURE:
		case types.CREATE_SESSION.FAILURE:
		case types.CREATE_EVENT.FAILURE:
		case types.GET_PENDING_DECISIONS.FAILURE:
		case types.GET_AVAILABLE_BANKS.FAILURE:
		case types.CREATE_DECISION.FAILURE:{
			return {
				...state,
				loading: false,
				creating_decision: false
			}
		}
		default:
			return state;
	}
};

export default AuthReducer;
