import React, { useCallback, useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone'
import { useSelector } from 'react-redux';
import loading from '../assets/loading.gif'

export default function UploadBankStatement({
    idSrc,
    setIdSrc,
    idFile,
    setIdFile,
    allowedBank,
    setAllowedBank,
    filePassword,
    setFilePassword,
    submitBankStatement,
    submittingDocument,
    fileError,
    resetUpload,
}){
    const {available_banks} = useSelector(
        (state) => state.auth
    )

    const onDrop = useCallback(acceptedFiles => {
        // Do something with the files
    
        if (acceptedFiles) {
          // console.log(acceptedFiles[0])
          let img = acceptedFiles[0];
          setIdSrc(URL.createObjectURL(img))
          setIdFile(img)
          // console.log(idSrc)
        }
      }, []) 
      
    useEffect(()=>{
        console.log(idSrc)
        console.log(idFile)
    },[idSrc, idFile])
    
    const { getRootProps, getInputProps, isDragActive } = useDropzone({ 
        accept: {
            'application/pdf': []
        },
        onDrop })
    return (
        <>
        {available_banks !== null &&
            <div className='px-[20px] pt-[5px] pb-[10px] !bg-white rounded-[5px]'>
            {(!submittingDocument) && fileError === "" &&
            <div className='modal_body'>
            <>
                <div className='text-[12px] text-center'>Upload recent six months bank statement</div>
                <p className='text-left font-bold pt-[10px]'>Allowed Banks</p>
                <select onChange={(e)=>setAllowedBank(e.target.value)
                }
                defaultValue={''}
                className='bg-gray-100'
                >
                    <option value={''} disabled>Select your bank</option> 
                    {available_banks.map((this_bank, index)=>{
                        return(
                            <option value={this_bank.code} key={index}>{this_bank.name}</option>
                        )
                    })}
                </select>
            </>
                {allowedBank !== '' &&
                <div>
                    <div>
                        <p className='text-left font-bold pt-[10px]'>Password</p>
                        <input type='text' className='bg-gray-100' placeholder='File password, If required.' 
                        onChange={(e)=>setFilePassword(e.target.value)}
                        />
                    </div>
                
                    <div {...getRootProps()}
                        className="id_image_box upload_box document-div !h-[150px]"
                        >
                        <input {...getInputProps()} />
                        {idSrc !== null && <div className='text'>File Uploaded 
                            {/* ({typeof idFile['name'] !== 'undefined' ? idFile['name'] : ''}) */}
                        </div>}
                        {idSrc === null &&
                        <>
                            {
                                isDragActive ? <div className='title'>Drop the files here ...</div> :
                                <div>
                                <div className='title'>Drop the bank statment here.</div>
                                <div className=''>
                                    {/* <div className='icon plus'></div> */}
                                    <div className='text'>Upload Bank Statement (PDF)</div>
                                </div>
                                </div>

                            }
                        </>
                        }
                    </div>
                </div>
            }
            {allowedBank === '' &&
                <div className='w-full h-[150px] bg-gray-200 rounded-[5px]'>

                </div>
            }
            </div>
            }

            {idFile !== null && !submittingDocument && fileError === "" &&
            <div>
                <div className='bottom_'>
                    <div className={`btn btn-primary`}
                    onClick={() => {
                        submitBankStatement()
                    }}
                    >
                        Continue
                    </div>
                </div>
            </div>
            }
            
            {submittingDocument &&
            <div className='flex justify-center content-center items-center gap-4'>
                <img src={loading} className="w-[30px] h-[30px]" alt="loading"/>
                <div className='text-center text-[12px] my-[10px]'>Submitting Document...</div>
            </div>
            }
            {fileError !== "" &&
                <div>
                    <div className={`icon failed !w-[30px] !h-[30px] !my-[20px]`}></div>
                    <div className='text-center text-[12px] my-[10px] text-red-500 !mb-[15px]'>{fileError}</div>
                    
                    <div className='flex gap-4 justify-center'>
                        <div className='btn !w-[150px] !h-[30px] !leading-[30px] 
                        !text-[10px] !text-center !mt-[0] !mb-[10px]
                        rounded-[5px] cursor-pointer'
                        onClick={()=>{
                            resetUpload()
                        }}
                        >Retry</div>
                    </div>
                </div>
            }
            
            
            </div>
        }
        </>
    );
}
